import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/Home"

const IndexPage = props => {
  const { location } = props
  return (
    <Layout path={location.pathname}>
      {
        // eslint-disable-next-line
        <SEO
          title="Прием катализаторов и сажевых фильтров по всей России и СНГ"
          description="Бесплатный выезд специалиста по Центральной России. 
        Мы гарантируем проведение анализа и выплату в день получения отработанного материала. 
        Наша компания скупает катализаторы и сажевые фильтры по высоким ценам до 75% LME."
        />
      }
      <Home city="Россия и СНГ" />
    </Layout>
  )
}

export default IndexPage
